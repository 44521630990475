import React from "react";
import { Fade } from "react-awesome-reveal";
const MeetCicada = () => {
  return (
    <div className="meetcicada">
      <div className="container">
        <div className="meetcicada-content">
          <div className="meetcicada-left">
            <Fade
              direction="up"
              cascade
              delay={20}
              duration={350}>
              <div className="meetcicada-left-header">
                <h1>Meet Cicada</h1>

                <img src="/logo-main.svg" alt="" />
              </div>
              <p className="meetcicada-left-text">
                Our philosophy is simple. Recruiting should reflect what you
                know, not who you know. Applications should be personal,
                meaningful, and intentional. Candidates should receive feedback
                from a company, no matter the outcome. And finally, the entire
                process should be beautifully simple and easy-to-use, even from
                a smartphone.
              </p>
            </Fade>
          </div>
          <img
            src="/svgs/meet_cicada.svg"
            alt="Cicada"
            className="meetcicada-right"
          />
        </div>
      </div>
    </div>
  );
};
export default MeetCicada;
