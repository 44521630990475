import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";

const Landing = () => {
  return (
    <div className="landing">
      <div className="container">
        <div className="landing-logo">
          <img src="/logo-main.png" alt="" />
          <h4 className="logo-text kayak">cicada</h4>
        </div>
        <div className="landing-content">
          <div className="landing-content-left">
            <Fade direction="up" delay={50} duration={500}>
              <h1 className="landing-title kayak">
                The future of hiring is here.
              </h1>
            </Fade>
            <Fade delay={250} duration={900}>
              <h3 className="landing-subtitle">
                A revolutionary way to find and recruit top candidates that is
                simple, effective, and meritocratic.
              </h3>
            </Fade>
            <Fade direction="down" delay={250} duration={1000}>
              <h4 className="landing-text kayak">Launching September 2023.</h4>
              <a className="landing-btn" href="https://app.cicada.day/login">
                Try Beta
              </a>
            </Fade>
            <Zoom delay={20} duration={350}>
              <div className="landing-content-explore">
                <img src="/svgs/landing_arrow.png" alt="" />
                <h4 className="landing-content-text">Start Exploring</h4>
              </div>
            </Zoom>
          </div>

          <img src="/svgs/landing.svg" alt="" className="landing-image" />
        </div>
      </div>
    </div>
  );
};
export default Landing;
