import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";

const Profile = () => {
  return (
    <div className="profile">
      <div className="container">
        <div className="profile-content">
          <div className="profile_left">
            <img src="/svgs/profile.png" alt="person" />
            <div className="profile_left-icons">
              <div className="profile_left-icon">
                <img src="/profile-icons/person.png" alt="person" />
                <h6 className="profile_left-icon-text">Personal Info</h6>
              </div>
              <div className="profile_left-icon">
                <img src="/profile-icons/experience.png" alt="person" />
                <h6 className="profile_left-icon-text">Your Experience</h6>
              </div>
              <div className="profile_left-icon">
                <img src="/profile-icons/skills.png" alt="person" />
                <h6 className="profile_left-icon-text">Skill Tests</h6>
              </div>
            </div>
            <h5 className="profile_left-bottom">
              Only the stuff that matters.
            </h5>
          </div>

          <div className="profile_right">
            <Fade cascade damping={0.125} duration={750}>
              <div className="profile_right-header">
                <h1 className="profile_right-header-titl kayak">
                  One Profile.
                </h1>
                <h1 className="profile_right-header-title kayak">
                  Unlimited Opportunities.
                </h1>
              </div>
              <p className="profile_right-description">
                Cicada takes the redundancy and bias out of the hiring process
                by giving candidates access to everything they need to apply for
                a job, all on one centralized platform.{" "}
              </p>
              <h5 className="profile_right-subtitle kayak">
                Reap the benefits.
              </h5>
              <div className="profile_right-benefits">
                <div className="profile_right-benefit">
                  <img src="/logo-main.png" alt="one" />
                  <p className="profile_right-benefit-text">
                    Companies are brought to you… Candidates match with the
                    perfect companies based on their career profile, interests,
                    and goals.{" "}
                  </p>
                </div>
                <div className="profile_right-benefit">
                  <img src="/logo-main.png" alt="one" />
                  <p className="profile_right-benefit-text">
                    Keep everything you need for job applications all within one
                    profile.
                  </p>
                </div>
                <div className="profile_right-benefit">
                  <img src="/logo-main.png" alt="one" />
                  <p className="profile_right-benefit-text">
                    Prove your expertise to companies by completing standardized
                    skill assessments that accurately reflect the skills needed
                    for the position.{" "}
                  </p>
                </div>
                <div className="profile_right-benefit">
                  <img src="/logo-main.png" alt="one" />
                  <p className="profile_right-benefit-text">
                    Easily apply to companies by completing required skill
                    assessments, then send your application in one click.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
