import React from "react";
import { Fade } from "react-awesome-reveal";
const Skills = () => {
  return (
    <div className="skills">
      <div className="container">
        <div className="skills-content">
          <img
            src="/svgs/skills.png"
            className="skills-content-left"
            alt="problem"
          />

          <div className="skills-content-right">
            <Fade direction="up" delay={50} duration={1000}>
              <h1 className="skills-title kayak">
                Show recruiters what you can do with
              </h1>

              <div className="skills-title-image">
                <h1 className="skills-title-image-text kayak">
                  Cicada Skill Tests
                </h1>
                <img src="/logo-main.svg" alt="" />
              </div>

              <p className="skills-text">
                No more wasting time taking skill assessments that carry little
                to no value in the application process. Our platform allows
                candidates to take meaningful, state-of-the-art skill
                assessments that companies directly use in the hiring process.
                Companies select which skill assessments they care about, and
                users can see what tests are considered for every unique
                application. The best part? These tests are automatically
                included in every application you submit - seamless and
                beautiful, {"(the Cicada way.)"}
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Skills;
