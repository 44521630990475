import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
const Problem = () => {
  return (
    <div className="problem">
      <div className="container">
        <div className="problem-content">
          <img src="/svgs/problem.svg" alt="problem" />

          <div className="problem-text">
            <Fade
              direction="up"
              cascade
              delay={20}
              duration={350}>
              <h1 className="problem-title kayak">The Problem</h1>
              <h3 className="problem-subtitle kayak">
                As students, we know. The traditional recruiting process sucks.
              </h3>
              <p className="problem-text">
                Candidates spend hours submitting 100s of applications, filling
                out their same information over and over, all to be denied by an
                automated resume scanner without a human ever reading it. We’ve
                set out to solve these fundamental problems.
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Problem;
