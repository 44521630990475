import React from "react";
import { Fade } from "react-awesome-reveal";
const HR = () => {
  return (
    <div className="hr">
      <img src="/svgs/hr_top-corner.svg" className="hr-top-corner" alt="" />
      <img src="/svgs/hr.svg" className="hr-graphic" alt="" />

      <div className="container">
        <div className="hr-left">
          <Fade cascade damping={0.125} duration={750}>
            <h1 className="hr-left-title kayak">Dear HR, you're welcome.</h1>
            <h3 className="hr-left-subtitle kayak">
              Companies can once-and-for-all find the thoughtfully risky,
              financially literate, entrepreneurially minded, extraverted,
              customer obsessed individual they are seeking with just a couple
              of clicks.
            </h3>
            <div className="hr-left-bullets">
              <div className="hr-left-bullet">
                <img src="/logo-blue.png" alt="" />
                <p className="hr-left-bullet-text">
                  Our data-driven platform uses AI to analyze candidates'
                  profiles thoroughly and brings the best candidates to you.{" "}
                </p>
              </div>
              <div className="hr-left-bullet">
                <img src="/logo-blue.png" alt="" />
                <p className="hr-left-bullet-text">
                  Find the right quality candidates using tailored skill
                  assessments that accurately reflect a candidate’s ability.
                </p>
              </div>
              <div className="hr-left-bullet">
                <img src="/logo-blue.png" alt="" />
                <p className="hr-left-bullet-text">
                  Create your own evaluation criteria to assess candidates
                  within the platform, and then filter candidates based on their
                  performance.
                </p>
              </div>
              <div className="hr-left-bullet">
                <img src="/logo-blue.png" alt="" />
                <p className="hr-left-bullet-text">
                  Send application invites to the best candidates with one
                  click.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};
export default HR;
