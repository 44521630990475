import React from "react";
import Landing from "./Landing";
import MeetCicada from "./MeetCicada";
import HR from "./HR";
import Problem from "./Problem";
import Profile from "./Profile";
import Skills from "./Skills";
import Footer from "./Footer";

const Main = () => {
  return (
    <div className="main">
      <Landing />
      <Problem />
      <MeetCicada />
      <Profile />
      <HR />
      <Skills />
      <Footer />
    </div>
  );
};
export default Main;
